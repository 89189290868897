<template>
  <div class="Track_Info">
    <a-row type="flex" justify="space-between" class="liner">
      <a-col :span="8">
        <span class="border-r zIndex" style="padding:0px 10px;">内部调动</span>
      </a-col>
      <a-col :span="4" style="text-align:right;">
        <span class="zIndex" style="font-size: 14px;">
          <a-icon :type="showLoop[1].bool ? 'down' : 'up'" @click="boolFn(1)" />
        </span>
      </a-col>
    </a-row>
    <div class="fieldset" v-show="showLoop[1].bool">
      <span class="fieldset-title">调动概况</span>
      <a-row style="margin:10px 0px;">
        <a-col :lg="{ span: 12 }" :xxl="{ span: 8 }"
          ><span class="titleName">调动类型</span>10002075</a-col
        >
        <a-col :lg="{ span: 12 }" :xxl="{ span: 8 }"
          ><span class="titleName">原岗位</span>2020-01-03</a-col
        >
        <a-col :lg="{ span: 12 }" :xxl="{ span: 8 }"
          ><span class="titleName">异动后岗位</span></a-col
        >
        <a-col :lg="{ span: 12 }" :xxl="{ span: 8 }"
          ><span class="titleName">异动后部门</span>0个月</a-col
        >
        <a-col :lg="{ span: 12 }" :xxl="{ span: 8 }"
          ><span class="titleName">生效日期</span>2020-01-03</a-col
        >
        <a-col :lg="{ span: 12 }" :xxl="{ span: 8 }"
          ><span class="titleName">异动原因</span></a-col
        >
      </a-row>
    </div>

    <a-row type="flex" justify="space-between" class="liner">
      <a-col :span="8">
        <span class="border-r zIndex" style="padding:0px 10px;">奖惩记录</span>
      </a-col>
      <a-col :span="4" style="text-align:right;">
        <span class="zIndex" style="font-size: 14px;">
          <a-icon :type="showLoop[2].bool ? 'down' : 'up'" @click="boolFn(2)" />
        </span>
      </a-col>
    </a-row>
    <div class="fieldset" v-show="showLoop[2].bool">
      <span class="fieldset-title">奖惩情况</span>
      <a-row style="margin:10px 0px;">
        <a-col :lg="{ span: 12 }" :xxl="{ span: 8 }"
          ><span class="titleName">奖惩类型</span>10002075</a-col
        >
        <a-col :lg="{ span: 12 }" :xxl="{ span: 8 }"
          ><span class="titleName">等级</span>2020-01-03</a-col
        >
        <a-col :lg="{ span: 12 }" :xxl="{ span: 8 }"
          ><span class="titleName">通报范围</span></a-col
        >
        <a-col :lg="{ span: 12 }" :xxl="{ span: 8 }"
          ><span class="titleName">日期</span>0个月</a-col
        >
        <a-col :lg="{ span: 12 }" :xxl="{ span: 8 }"
          ><span class="titleName">奖惩内容</span>2020-01-03</a-col
        >
        <a-col :lg="{ span: 12 }" :xxl="{ span: 8 }"
          ><span class="titleName">事件描述</span></a-col
        >
      </a-row>
    </div>

    <a-row type="flex" justify="space-between" class="liner">
      <a-col :span="8">
        <span class="border-r zIndex" style="padding:0px 10px;"
          >普通跟踪记录</span
        >
      </a-col>
      <a-col :span="4" style="text-align:right;">
        <span class="zIndex" style="font-size: 14px;">
          <a-icon :type="showLoop[3].bool ? 'down' : 'up'" @click="boolFn(3)" />
        </span>
      </a-col>
    </a-row>
    <div class="fieldset" v-show="showLoop[3].bool">
      <span class="fieldset-title">跟踪情况</span>
      <a-row style="margin:10px 0px;">
        <a-col :lg="{ span: 12 }" :xxl="{ span: 8 }"
          ><span class="titleName">事件时间</span>10002075</a-col
        >
        <a-col :lg="{ span: 12 }" :xxl="{ span: 8 }"
          ><span class="titleName">创建人</span>2020-01-03</a-col
        >
        <a-col :lg="{ span: 12 }" :xxl="{ span: 8 }"
          ><span class="titleName">创建时间</span></a-col
        >
        <a-col :lg="{ span: 12 }" :xxl="{ span: 24 }"
          ><span class="titleName">详细内容</span>0个月</a-col
        >
      </a-row>
    </div>

    <a-row type="flex" justify="space-between" class="liner">
      <a-col :span="8">
        <span class="border-r zIndex" style="padding:0px 10px;">离职记录</span>
      </a-col>
      <a-col :span="4" style="text-align:right;">
        <span class="zIndex" style="font-size: 14px;">
          <a-icon :type="showLoop[4].bool ? 'down' : 'up'" @click="boolFn(4)" />
        </span>
      </a-col>
    </a-row>
    <div class="fieldset" v-show="showLoop[4].bool">
      <span class="fieldset-title">离职记录</span>
      <a-row style="margin:10px 0px;">
        <a-col :lg="{ span: 12 }" :xxl="{ span: 8 }"
          ><span class="titleName">离职日期</span>10002075</a-col
        >
        <a-col :lg="{ span: 12 }" :xxl="{ span: 8 }"
          ><span class="titleName">创建人</span>2020-01-03</a-col
        >
        <a-col :lg="{ span: 12 }" :xxl="{ span: 8 }"
          ><span class="titleName">创建时间</span></a-col
        >
        <a-col :lg="{ span: 12 }" :xxl="{ span: 8 }"
          ><span class="titleName">人资面谈人</span></a-col
        >
        <a-col :lg="{ span: 24 }" :xxl="{ span: 16 }"
          ><span class="titleName">详细内容</span>0个月</a-col
        >
      </a-row>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Track_Info',
  data () {
    return {
      showLoop: [
        { bool: false, operation: false },
        { bool: true, operation: false },
        { bool: true, operation: false },
        { bool: true, operation: false },
        { bool: true, operation: false },
        { bool: true, operation: false }
      ]
    }
  },
  methods: {
    boolFn (index) {
      console.log(this.showLoop[index])
      this.showLoop[index].bool = !this.showLoop[index].bool
      // let bool=!this.showLoop[index].bool;
      // this.showLoop.splice(index,1);
      // this.showLoop.splice(index,0,bool);
    }
  }
}
</script>
<style lang="less" scoped>
.Track_Info {
  font-size: 14px;
  color: #333333;
  .liner {
    position: relative;
    margin: 20px 0px;
    font-size: 16px;
    color: #333333;
    &::before {
      content: '';
      position: absolute;
      left: 0px;
      top: 50%;
      width: 100%;
      height: 0px;
      border-top: 1px solid #e8e8e8;
    }
    .zIndex {
      background-color: #ffffff;
      z-index: 10;
      padding-left: 12px;
      position: relative;
    }
    .addtitle {
      margin: 12px 0px 12px 50%;
      transform: translateX(-50%);
      display: inline-block;
      padding-right: 12px;
    }
  }
  .titleName {
    color: #999999;
    line-height: 26px;
    margin-right: 10px;
  }

  .fieldset {
    padding: 10px 30px;
    border: 1px solid #eeeeee;
    border-radius: 6px;
    position: relative;
    .fieldset-title {
      position: absolute;
      left: 30px;
      top: -14px;
      padding: 2px 10px;
      background-color: #fff;
    }
  }
  .box {
    padding: 0px 30px;
  }
  .inlineBtn {
    white-space: nowrap;
    color: #333333;
    line-height: 30px;
    margin-right: 70px;
    display: inline-block;
    &:hover {
      color: #096dd9;
    }
  }
  .imgArea {
    display: inline-table;
    margin: 0 20px 10px 0px;
    text-align: center;
    position: relative;
    .delBtn {
      position: absolute;
      top: -10px;
      right: -10px;
      cursor: pointer;
      font-size: 20px;
    }
    img {
      width: 84px;
      height: 84px;
      border-radius: 4px;
    }
    p {
      line-height: 30px;
    }
  }
  .icon-bianji {
    margin-right: 10px;
    font-weight: 500;
  }
}
.iconfont:hover {
  color: #3149ef;
}
</style>
